<template>
  <div class="content_box beforetab mt0">
    <!-- content_box -->
    <div id="realgrid" style="height: 400px;" />
  </div><!-- content_box // -->
</template>

<script>
import shippingLog from '@/api/rest/trans/shippingLog'
import commons from '@/api/services/commons'
import { GridView, LocalDataProvider } from 'realgrid'

let gridView = GridView
let provider = LocalDataProvider

export const fields = [
  {
    fieldName: 'blNo',
    dataType: 'text'
  },
  {
    fieldName: 'kindNm',
    dataType: 'text'
  },
  {
    fieldName: 'logDate',
    dataType: 'text'
  },
  {
    fieldName: 'logTime',
    dataType: 'text'
  },
  {
    fieldName: 'logCont',
    dataType: 'text'
  },
  {
    fieldName: 'fstEntUno',
    dataType: 'text'
  },
  {
    fieldName: 'fstEntUnm',
    dataType: 'text'
  },
  {
    fieldName: 'eta',
    dataType: 'text'
  },
  {
    fieldName: 'rmk2',
    dataType: 'text'
  }
]

export const columns = [
  {
    name: 'blNo',
    fieldName: 'blNo',
    type: 'text',
    width: '40',
    header: {
      text: 'B/L No.'
    }
  },
  {
    name: 'kindNm',
    fieldName: 'kindNm',
    type: 'text',
    width: '50',
    header: {
      text: 'Category'
    },
    styleName: 'realgrid-pre-wrap'
  },
  {
    name: 'logDate',
    fieldName: 'logDate',
    type: 'text',
    width: '40',
    header: {
      text: 'Log Date'
    }
  },
  {
    name: 'logTime',
    fieldName: 'logTime',
    type: 'text',
    width: '40',
    header: {
      text: 'Log Time'
    }
  },
  {
    name: 'logCont',
    fieldName: 'logCont',
    type: 'text',
    width: '100',
    header: {
        text: 'Log & History Description'
    },
    styleName: 'realgrid-pre-wrap text_left'
  },
  {
    name: 'fstEntUnm',
    fieldName: 'fstEntUnm',
    type: 'text',
    width: '50',
    header: {
        text: 'PIC'
    }
  },
  {
    name: 'eta',
    fieldName: 'eta',
    type: 'text',
    width: '50',
    header: {
        text: 'ETA'
    },
    styleName: 'realgrid-pre-wrap'
  },
  {
    name: 'rmk2',
    fieldName: 'rmk2',
    type: 'text',
    width: '50',
    header: {
        text: 'FAX No.'
    },
    styleName: 'realgrid-pre-wrap'
  }
]

export default {
  name: 'ShippingLogBLPop',
  props: {
    propData: {
      type: Object,
      default: function () {
        return {
          blNo: ''
        }
      }
    }
  },
  data () {
    return {
      items: [],
      kinds: {}
    }
  },
  created: function () {

  },
  mounted: function () {
    console.log('B/L Pop')

    //리얼그리드 셋팅
    provider = new LocalDataProvider(true)
    gridView = new GridView('realgrid')

    gridView.setDataSource(provider)
    provider.setFields(fields)
    gridView.setColumns(columns)

    this.setMessageProperty()
    gridView.displayOptions.rowHeight = -1
    gridView.displayOptions.minRowHeight = 36
    gridView.displayOptions.fitStyle = 'fill'

    gridView.setEditOptions({
      editable: false,
      updatable: false
    })

    $('#realgrid').height('350px')

    //인디케이터, 상태바, 체크바 비활성화
    gridView.setRowIndicator({ visible: false })
    gridView.setStateBar({ visible: false })
    gridView.setCheckBar({ visible: false })

    this.init()
  },
  methods: {
    async init () {
      //공통코드 셋팅
      const codeData = await commons.getCommonsCodesCommon(['01009'])
      this.kinds = codeData['01009']

      //메인데이터 셋팅
      const mainData = await shippingLog.getShippingLogBl({ blNo: this.propData.blNo })
      const blLogList = mainData.data

      //메인데이터, 공통코드 가공 후 셋팅
      this.items = blLogList.map((item) => {
        //Kind 네임 셋팅
        if (this.kinds) {
          item.kindNm = this.kinds.filter((kind) => {
            return kind.cd === item.blLogCatCd
          })[0].cdNm
        }

        //LOG DATE 셋팅
        item.logDate = this.$ekmtcCommon.changeDatePattern(item.blLogDtm.substring(0, 8), '.')

        //LOG TIME 셋팅
        const logTime = item.blLogDtm.substring(8, 12)
        item.logTime = logTime.substring(0, 2) + ':' + logTime.substring(2, 4)

        //ETA 셋팅
        if (item.rmk1) {
          const etaDate = this.$ekmtcCommon.changeDatePattern(item.rmk1.substring(0, 8), '.')
          const etaTime = item.rmk1.substring(8, 10) + ':' + item.rmk1.substring(10, 12)
          item.eta = etaDate + ' ' + etaTime
        }

        return item
      })
      provider.setRows(this.items)
    },
    //다국어 적용
    setMessageProperty () {
      const header1 = gridView.getColumnProperty('blNo', 'header')
      const header2 = gridView.getColumnProperty('kindNm', 'header')
      const header3 = gridView.getColumnProperty('logDate', 'header')
      const header4 = gridView.getColumnProperty('logTime', 'header')
      const header5 = gridView.getColumnProperty('logCont', 'header')
      const header6 = gridView.getColumnProperty('fstEntUnm', 'header')
      const header7 = gridView.getColumnProperty('eta', 'header')
      const header8 = gridView.getColumnProperty('rmk2', 'header')

      header1.text = this.$t('msg.ONEX010T080.001') //B/L No.
      header2.text = this.$t('msg.ONEX010T080.002') //Category
      header3.text = this.$t('msg.ONEX010T080.003') //Log Date
      header4.text = this.$t('msg.ONEX010T080.004') //Log Time
      header5.text = this.$t('msg.ONEX010T080.005') //Log & History Description
      header6.text = this.$t('msg.ONEX010T080.006') //PIC
      header7.text = this.$t('msg.ONEX010T080.007') //ETA
      header8.text = this.$t('msg.ONEX010T080.008') //FAX No.

      gridView.setColumnProperty('blNo', 'header', header1)
      gridView.setColumnProperty('kindNm', 'header', header2)
      gridView.setColumnProperty('logDate', 'header', header3)
      gridView.setColumnProperty('logTime', 'header', header4)
      gridView.setColumnProperty('logCont', 'header', header5)
      gridView.setColumnProperty('fstEntUnm', 'header', header6)
      gridView.setColumnProperty('eta', 'header', header7)
      gridView.setColumnProperty('rmk2', 'header', header8)
    }
  }
}
</script>

<style>
.realgrid-pre-wrap{
  white-space: pre-wrap
}
</style>

<template>
  <div class="content_box beforetab mt0">
    <!-- content_box -->
    <div id="realgrid" style="height: 400px;" />
  </div><!-- content_box // -->
</template>

<script>
import shippingLog from '@/api/rest/trans/shippingLog'
import commons from '@/api/services/commons'
import { GridView, LocalDataProvider } from 'realgrid'

let gridView = GridView
let provider = LocalDataProvider

export const fields = [
  {
    fieldName: 'bkgNo',
    dataType: 'text'
  },
  {
    fieldName: 'categoryNm',
    dataType: 'text'
  },
  {
    fieldName: 'logDt',
    dataType: 'text'
  },
  {
    fieldName: 'logTm',
    dataType: 'text'
  },
  {
    fieldName: 'logOldCont',
    dataType: 'text'
  },
  {
    fieldName: 'logNewCont',
    dataType: 'text'
  },
  {
    fieldName: 'fstEntUno',
    dataType: 'text'
  },
  {
    fieldName: 'fstEntUnm',
    dataType: 'text'
  },
  {
    fieldName: 'ediStatusNm',
    dataType: 'text'
  },
  {
    fieldName: 'ediTrmsFqcy',
    dataType: 'text'
  }
]

export const columns = [
  {
    name: 'bkgNo',
    fieldName: 'bkgNo',
    type: 'text',
    width: '70',
    header: {
      text: 'Booking No'
    }
  },
  {
    name: 'categoryNm',
    fieldName: 'categoryNm',
    type: 'text',
    width: '90',
    header: {
      text: 'Category'
    },
    styleName: 'realgrid-pre-wrap'
  },
  {
    name: 'logDt',
    fieldName: 'logDt',
    type: 'text',
    width: '60',
    header: {
      text: 'Log Date'
    }
  },
  {
    name: 'logTm',
    fieldName: 'logTm',
    type: 'text',
    width: '60',
    header: {
      text: 'Log Time'
    }
  },
  {
    name: 'logOldCont',
    fieldName: 'logOldCont',
    type: 'text',
    width: '100',
    header: {
        text: 'Old Content'
    },
    styleName: 'realgrid-pre-wrap'
  },
  {
    name: 'logNewCont',
    fieldName: 'logNewCont',
    type: 'text',
    width: '100',
    header: {
        text: 'New Content'
    },
    styleName: 'realgrid-pre-wrap'
  },
  {
    name: 'fstEntUnm',
    fieldName: 'fstEntUnm',
    type: 'data',
    width: '100',
    header: {
        text: 'User'
    }
  },
  {
    name: 'ediStatusNm',
    fieldName: 'ediStatusNm',
    type: 'data',
    width: '60',
    header: {
        text: 'EDI Status'
    }
  },
  {
    name: 'ediTrmsFqcy',
    fieldName: 'ediTrmsFqcy',
    type: 'data',
    width: '70',
    header: {
        text: 'Sent Count'
    }
  }
]

export default {
  name: 'ShippingLogBookingPop',
  props: {
    propData: {
      type: Object,
      default: function () {
        return {
          bkgNo: ''
        }
      }
    }
  },
  data () {
    return {
      bkgNo: '',
      items: [],
      categoryNm: ''
    }
  },
  mounted () {
    console.log('Booking Pop')

    //리얼그리드 셋팅
    provider = new LocalDataProvider(true)
    gridView = new GridView('realgrid')

    gridView.setDataSource(provider)
    provider.setFields(fields)
    gridView.setColumns(columns)

    this.setMessageProperty()
    gridView.displayOptions.rowHeight = -1
    gridView.displayOptions.minRowHeight = 36
    gridView.displayOptions.fitStyle = 'fill'

    gridView.setEditOptions({
      editable: false,
      updatable: false
    })

    $('#realgrid').height('350px')

    //인디케이터, 상태바, 체크바 비활성화
    gridView.setRowIndicator({ visible: false })
    gridView.setStateBar({ visible: false })
    gridView.setCheckBar({ visible: false })

    this.init()
  },
  methods: {
    async init () {
      //1.공통코드 초기화
      const codeData = await commons.getCommonsCodesCommon(['01008'])
      this.categoryNm = codeData['01008']

      const mainData = await shippingLog.getShippingLogBooking({ bkgNo: this.propData.bkgNo })
      const bookingLogList = mainData.data

      this.items = bookingLogList.map((item) => {
        //카테고리 네임 셋팅
        item.categoryNm = this.categoryNm.filter((category) => {
          return category.cd === item.bkgLogCatCd
        })[0].cdNm

        //EDI Status 셋팅
        item.ediStatusNm = item.ediSndCatCd === '9' ? 'New' : ''

        //Log Date 셋팅
        item.logDt = this.$ekmtcCommon.changeDatePattern(item.logDt, '.')

        //Log Time 셋팅
        item.logTm = item.logTm.substring(0, 2) + ':' + item.logTm.substring(2, 4)

        return item
      })

      provider.setRows(this.items)
    },
    //다국어 적용
    setMessageProperty () {
      const header1 = gridView.getColumnProperty('bkgNo', 'header')
      const header2 = gridView.getColumnProperty('categoryNm', 'header')
      const header3 = gridView.getColumnProperty('logDt', 'header')
      const header4 = gridView.getColumnProperty('logTm', 'header')
      const header5 = gridView.getColumnProperty('logOldCont', 'header')
      const header6 = gridView.getColumnProperty('logNewCont', 'header')
      const header7 = gridView.getColumnProperty('fstEntUnm', 'header')
      const header8 = gridView.getColumnProperty('ediStatusNm', 'header')
      const header9 = gridView.getColumnProperty('ediTrmsFqcy', 'header')

      header1.text = this.$t('msg.ONEX010T070.006') //Booking No
      header2.text = this.$t('msg.ONEX010T070.007') //Category
      header3.text = this.$t('msg.ONEX010T070.008') //Log Date
      header4.text = this.$t('msg.ONEX010T070.009') //Log Time
      header5.text = this.$t('msg.ONEX010T070.010') //Old Content
      header6.text = this.$t('msg.ONEX010T070.011') //New Content
      header7.text = this.$t('msg.ONEX010T070.012') //User
      header8.text = this.$t('msg.ONEX010T070.013') //EDI Status
      header9.text = this.$t('msg.ONEX010T070.014') //Sent Count

      gridView.setColumnProperty('bkgNo', 'header', header1)
      gridView.setColumnProperty('categoryNm', 'header', header2)
      gridView.setColumnProperty('logDt', 'header', header3)
      gridView.setColumnProperty('logTm', 'header', header4)
      gridView.setColumnProperty('logOldCont', 'header', header5)
      gridView.setColumnProperty('logNewCont', 'header', header6)
      gridView.setColumnProperty('fstEntUno', 'header', header7)
      gridView.setColumnProperty('ediStatusNm', 'header', header8)
      gridView.setColumnProperty('ediTrmsFqcy', 'header', header9)
    }
  }
}
</script>

<style scoped>
.realgrid-pre-wrap{
  white-space: pre-wrap
}
</style>

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "content_box beforetab mt0" }, [
    _c("div", { attrs: { id: "realgrid" } }),
    _vm.items.length > 0
      ? _c("div", { staticClass: "paging" }, [
          _vm.isPaging1
            ? _c(
                "a",
                {
                  staticClass: "btn prev",
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.setPrevPage()
                    },
                  },
                },
                [_vm._v("Prev")]
              )
            : _vm._e(),
          _c("a", [
            _vm._v(_vm._s(_vm.currentPage + 1) + "/" + _vm._s(_vm.totalPage)),
          ]),
          _c(
            "a",
            {
              staticClass: "btn next",
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.setNextPage()
                },
              },
            },
            [_vm._v("Next")]
          ),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <div class="content_box beforetab mt0"><!-- content_box -->
    <div id="realgrid" />
    <!-- paging -->
    <div
      v-if="items.length > 0"
      class="paging"
    >
      <a
        v-if="isPaging1"
        class="btn prev"
        @click.prevent="setPrevPage()"
      >Prev</a>
      <a>{{ currentPage + 1 }}/{{ totalPage }}</a>
      <a
        class="btn next"
        @click.prevent="setNextPage()"
      >Next</a>
    </div>
  </div><!-- content_box // -->
</template>

<script>
import shippingLog from '@/api/rest/trans/shippingLog'
import { GridView, LocalDataProvider } from 'realgrid'

let gridView = GridView
let provider = LocalDataProvider

export const fields = [
  {
    fieldName: 'rowSeq',
    dataType: 'text'
  },
  {
    fieldName: 'doRno',
    dataType: 'text'
  },
  {
    fieldName: 'logDt',
    dataType: 'text'
  },
  {
    fieldName: 'logTm',
    dataType: 'text'
  },
  {
    fieldName: 'fstEntUno',
    dataType: 'text'
  },
  {
    fieldName: 'doItemNm',
    dataType: 'text'
  },
  {
    fieldName: 'logOldCont',
    dataType: 'text'
  },
  {
    fieldName: 'logNewCont',
    dataType: 'text'
  }
]

export const columns = [
  {
    name: 'rowSeq',
    fieldName: 'rowSeq',
    type: 'text',
    width: '20',
    header: {
      text: 'Seq'
    }
  },
  {
    name: 'doRno',
    fieldName: 'doRno',
    type: 'text',
    width: '40',
    header: {
      text: 'D/O No'
    }
  },
  {
    name: 'logDt',
    fieldName: 'logDt',
    type: 'text',
    width: '40',
    header: {
      text: 'Log Date'
    }
  },
  {
    name: 'logTm',
    fieldName: 'logTm',
    type: 'text',
    width: '40',
    header: {
      text: 'Log Time'
    }
  },
  {
    name: 'fstEntUno',
    fieldName: 'fstEntUno',
    type: 'text',
    width: '40',
    header: {
        text: 'Made By'
    }
  },
  {
    name: 'doItemNm',
    fieldName: 'doItemNm',
    type: 'text',
    width: '50',
    header: {
        text: 'Item'
    },
    styleName: 'realgrid-pre-wrap'
  },
  {
    name: 'logOldCont',
    fieldName: 'logOldCont',
    type: 'data',
    width: '80',
    header: {
        text: 'Old Content'
    },
    styleName: 'realgrid-pre-wrap'
  },
  {
    name: 'logNewCont',
    fieldName: 'logNewCont',
    type: 'data',
    width: '80',
    header: {
        text: 'New Content'
    },
    styleName: 'realgrid-pre-wrap'
  }
]

export default {
  name: 'ShippingLogDOPop',
  data () {
    return {
      items: [],
      isPaging1: true,
      currentPage: 0,
      totalPage: 0
    }
  },
  created: function () {

  },
  mounted: function () {
    console.log('D/O Pop')

    //리얼그리드 셋팅
    provider = new LocalDataProvider(true)
    gridView = new GridView('realgrid')

    gridView.setDataSource(provider)
    provider.setFields(fields)
    gridView.setColumns(columns)

    //this.setMessageProperty()
    gridView.displayOptions.rowHeight = -1
    gridView.displayOptions.minRowHeight = 36
    gridView.displayOptions.fitStyle = 'fill'

    gridView.setEditOptions({
      editable: false,
      updatable: false
    })

    $('#realgrid').height('350px')

    //인디케이터, 상태바, 체크바 비활성화
    gridView.setRowIndicator({ visible: false })
    gridView.setStateBar({ visible: false })
    gridView.setCheckBar({ visible: false })

    this.init()

    //페이징
    gridView.setPaging(true, 10)
  },
  methods: {
    async init () {
      const mainData = await shippingLog.getShippingLogDo({ doRno: 'INC3335590' })

      this.items = mainData.data.map((item) => {
        //Log Date 셋팅
        item.logDt = this.$ekmtcCommon.changeDatePattern(item.logDt, '.')

        //Log Time 셋팅
        item.logTm = item.logTm.substring(0, 2) + ':' + item.logTm.substring(2, 4)

        return item
      })
      provider.setRows(this.items)

      //페이징(axios는 비동기 처리기 때문에 promise 처리된 then() 안에서 사용)
      this.currentPage = gridView.getPage() //현재 페이지 반환
      this.totalPage = gridView.getPageCount() //전체페이지 개수 반환
    },
    setPrevPage: function () {
      gridView.setPage(this.currentPage - 1)
      this.currentPage = gridView.getPage()
    },
    setNextPage: function () {
      gridView.setPage(this.currentPage + 1)
      this.currentPage = gridView.getPage()
    },
    //다국어 적용
    setMessageProperty () {
      const header1 = gridView.getColumnProperty('rowSeq', 'header')
      const header2 = gridView.getColumnProperty('doRno', 'header')
      const header3 = gridView.getColumnProperty('logDt', 'header')
      const header4 = gridView.getColumnProperty('logTm', 'header')
      const header5 = gridView.getColumnProperty('fstEntUno', 'header')
      const header6 = gridView.getColumnProperty('doItemNm', 'header')
      const header7 = gridView.getColumnProperty('logOldCont', 'header')
      const header8 = gridView.getColumnProperty('logNewCont', 'header')

      header1.text = this.$t('msg.ONEX010P100_TMP.001') //Seq
      header2.text = this.$t('msg.ONEX010P100_TMP.002') //D/O No
      header3.text = this.$t('msg.ONEX010P100_TMP.003') //Log Date
      header4.text = this.$t('msg.ONEX010P100_TMP.004') //Log Time
      header5.text = this.$t('msg.ONEX010P100_TMP.005') //Made By
      header6.text = this.$t('msg.ONEX010P100_TMP.006') //Item
      header7.text = this.$t('msg.ONEX010P100_TMP.007') //Old Content
      header8.text = this.$t('msg.ONEX010P100_TMP.008') //New Content

      gridView.setColumnProperty('rowSeq', 'header', header1)
      gridView.setColumnProperty('doRno', 'header', header2)
      gridView.setColumnProperty('logDt', 'header', header3)
      gridView.setColumnProperty('logTm', 'header', header4)
      gridView.setColumnProperty('fstEntUno', 'header', header5)
      gridView.setColumnProperty('doItemNm', 'header', header6)
      gridView.setColumnProperty('logOldCont', 'header', header7)
      gridView.setColumnProperty('logNewCont', 'header', header8)
    }
  }
}
</script>

<style scoped>
.realgrid-pre-wrap{
  white-space: pre-wrap
}
</style>

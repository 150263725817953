import Send from '../../trans.client.js'

//주석정의방법 : 컨트롤러 메소드명 - 메소드에 대한 간단 주석 - REST URI
export default {
  //[TAB1] : 선적로그조회 - Booking Log 조회
  getShippingLogBooking (formData) {
    return Send({
      url: '/trans/shippinglog/booking/' + formData.bkgNo,
      method: 'get'
    })
  },
  //[TAB2] : 선적로그조회 - B/L 조회
  getShippingLogBl (formData) {
    return Send({
      url: '/trans/shippinglog/bl/' + formData.blNo,
      method: 'get'
    })
  },
  //[TAB3] : 선적로그조회 - D/O 조회
  getShippingLogDo (formData) {
    return Send({
      url: '/trans/shippinglog/do/' + formData.doRno,
      method: 'get'
    })
  },
  //선적로그조회 - C/A DETAIL(B/L 상세)
  getShippingLogBlDetail (formData) {
    return Send({
      url: '/trans/shippinglog/ca-detail/' + formData.blNo,
      method: 'get',
      params: {
        caNo: formData.caNo,
        caFlag: formData.caFlag
      }
    })
  }
}

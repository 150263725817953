var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "popup_wrap",
      staticStyle: { width: "1050px", height: "600px" },
    },
    [
      _c("button", {
        staticClass: "layer_close",
        on: {
          click: function ($event) {
            return _vm.$emit("close")
          },
        },
      }),
      _c(
        "div",
        { staticClass: "popup_cont" },
        [
          _c("h1", { staticClass: "page_title" }, [
            _vm._v(_vm._s(_vm.$t("msg.ONEX010T070.001"))),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "tab" }, [
            _c("ul", [
              _c(
                "li",
                {
                  class: {
                    on: _vm.tabName === "ShippingLogBookingPop" ? true : false,
                  },
                },
                [
                  _c(
                    "a",
                    {
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.changeTab("ShippingLogBookingPop")
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("msg.ONEX010T070.002")))]
                  ),
                ]
              ),
              _vm._v(" "),
              _vm.propData.blNo
                ? _c(
                    "li",
                    {
                      class: {
                        on: _vm.tabName === "ShippingLogBLPop" ? true : false,
                      },
                    },
                    [
                      _c(
                        "a",
                        {
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.changeTab("ShippingLogBLPop")
                            },
                          },
                        },
                        [_vm._v(_vm._s(_vm.$t("msg.ONEX010T070.003")))]
                      ),
                    ]
                  )
                : _vm._e(),
            ]),
          ]),
          _c(
            "keep-alive",
            [
              _c(_vm.componentName, {
                tag: "component",
                attrs: { "prop-data": _vm.propData },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }